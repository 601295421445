<template>
  <div class="container">
    <div class="checkin">
      <el-row :gutter="20">
        <el-col :span="5">
          <serviceleft :activeIndex="leftPannelSelected" />
        </el-col>
        <el-col :span="19">
          <div class="pannel-top"></div>
          <div class="right_pannel">
            <div
              style="height:300px;  background-color:#ffffff;border-radius:5px;position:absolute;top:60px;left:180px; "
            >
              <div class="service-query">
                <!-- <div>
                  <h3 class="service-title">自助值机系统</h3>
                  <p class="service-prompt">* 我们在部分航线上提供预选座位服务，但需在值机时间开放后重新办理值机手续。</p>
                </div>-->
                <ul class="service-forms">
                    <div class="wxapp_dialog">
                                <div class="title">请前往<span class="b">湖南航空小程序</span>使用值机选座服务，快人一步 即享舒适！</div>
                                <div class="qrcode">
                                    <img src="../../assets/wxapp_code.jpg" style="height:190px;"/>
                                </div>
                                <div class="tips">微信扫一扫打开小程序</div>
                            </div>
                </ul>
              </div>
            </div>
            <div style="margin-top:350px;">
              <div class="warm-prompt"  v-html="warmTips">
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <aliasViewDialog ref="aliasdialog"></aliasViewDialog>
  </div>
</template>
<script>
import serviceleft from "../../components/ServiceLeft";
import aliasViewDialog from "../../components/AliasViewDialog";
import wxAppDialog from "../../components/wxAppDialog";
import { fetchCheckinFlightList, getOneByAliasCode,queryNoticeList } from "@/api/newsapi";
export default {
  components: { serviceleft, aliasViewDialog },
  data() {
    return {
      leftPannelSelected: "li_checkin",
      input: "",
      cert: "",
      certType: "身份证",
      name: "",
      phone: "",
      warmTips: "",
      checked: false,
      loadingShow: false,
      orderNeedKnows: []
    };
  },
  mounted: function() {
    document.title = this.$route.meta.title;
    // console.log( this.$route.query.deptAirportCode+" "+this.$route.query.arrAirportCode+" "+this.$route.query.deptDate)
    // {certNo:this.checkInFilter.certNo,passName:this.checkInFilter.passName,passTel:this.checkInFilter.passTel}
    this.cert = this.$route.query.certNo;
    this.name = this.$route.query.passName;
    this.phone = this.$route.query.passTel;
    this.loadWarm();
    this._queryOrderNeedKnowDictKeys()
  },
  methods: {
    loadWarm() {
      getOneByAliasCode({ aliasCode: "checkin_warm_tips" }).then(res => {
        if (res.success) {
          this.warmTips = res.data.newsContent;
        }
      });
    },
    selectPannel(pannelId) {
      // console.log(pannelId);
      document.getElementById("li_booking").setAttribute("class", "");
      document.getElementById("li_aoc").setAttribute("class", "");
      document.getElementById("li_checkin").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_xl").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    validateMobileNumber(number) {
      if (number === "") {
        return { message: "请填写联系手机" };
      }
      let re = /^(13[0-9]|147|145|15[0-9]|17[0-9]|18[0-9])[0-9]{8}$/;
      if (!re.test(number)) {
        return { message: "联系手机不合法,请输入正确的手机号！" };
      }
      return undefined;
    },
    checkInAction() {
      // this.$alert('系统维护，请稍后在试', '温馨提示', {
      //   confirmButtonText: '已了解',
      //   callback: action => {
      //     // this.$message({
      //     //   type: 'info',
      //     //   message: `action: ${ action }`
      //     // });
      //   }
      // });
      if (this.cert.length <= 0) {
        this.$message({
          showClose: true,
          message: "请输入正确的票号或证件号",
          type: "warning"
        });

        this.$refs.certNo.focus();
        return;
      }
      if (this.name.length <= 0) {
        this.$message({
          showClose: true,
          message: "旅客姓名不能为空，请核实输入",
          type: "warning"
        });

        this.$refs.passName.focus();
        return;
      }
      let validatePhoneResult = this.validateMobileNumber(this.phone);
      if (validatePhoneResult != undefined) {
        this.$message({
          showClose: true,
          message: validatePhoneResult.message,
          type: "warning"
        });

        this.$refs.phone.focus();
        return;
      }
      if (!this.checked) {
        this.$message({
          showClose: true,
          message: "请先阅读并同意值机协议！",
          type: "warning"
        });

        return;
      }
      let _this = this;
      let params = {
        certType: "NI",
        certNo: this.cert,
        passengerName: this.name,
        mobile: this.phone
      };
      _this.loadingShow = true;
      fetchCheckinFlightList(params)
        .then(res => {
          _this.loadingShow = false;
          if (res.success) {
            let userInput = {
              certType: "NI",
              cert: _this.cert,
              name: _this.name,
              phone: _this.phone
            };
            localStorage.setItem(
              "airtravel-checkin-userInput",
              JSON.stringify(userInput)
            );
            localStorage.setItem(
              "airtravel-checkin-list",
              JSON.stringify(res.values)
            );
            _this.$router.push({
              name: "checkin_list"
            });
          } else {
            _this.loadingShow = false;
            if (res.errorMessage.indexOf("异常") != -1) {
              this.$alert("未查询到可用客票信息", "温馨提示", {
                confirmButtonText: "知道了"
              });
            } else {
              this.$alert(res.errorMessage, "温馨提示", {
                confirmButtonText: "知道了"
              });
            }
          }
        })
        .catch(err => {
          _this.loadingShow = false;
          this.$alert("系统异常，请稍候重试", "温馨提示", {
            confirmButtonText: "知道了"
          });
        });
    },

    _queryOrderNeedKnowDictKeys() {
      queryNoticeList({ key: "SELF_CHECK_IN_NOTICE_ALIAS_PC" }).then((res) => {
        if (res.success) {
          this.orderNeedKnows = JSON.parse(res.data)
        }
      });
    },
  }
};
</script>
<style scoped>
.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}

.left_pannel {
  height: 500px;
  background-color: #f7f7f7;
  overflow: hidden;
}

.right_pannel {
  min-height: 600px;
  position: relative;
  border: 1px solid #ededed;
  margin-top: 10px;
  background: #ffffff;
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}

.col_menu ul {
  margin-bottom: 20px;
}

ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}

.col_menu li {
  margin: 10px 0;
  position: relative;
}

.col_menu li.current {
  background: none;
}

.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}

.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}

a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}

.warm-prompt {
  padding: 40px 50px 80px;
}

.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.service-query {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding: 8px 44px 0;
  width: 578px;
  background-color: #fff;
  border-radius: 4px;
  /* -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05); */
}

.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}

.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}

.service-query .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}

.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}

.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  color: #009ed0;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #009ed0;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}

.popcontext .tab {
  z-index: 1000;
  width: 240px;
}

.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #ffffff !important;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

/* border-bottom: 1px solid #d6003a; */
.popcontext .tab li:nth-child(2) {
  border-top: 1px solid #d6003a;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}

.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}

.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}
.pannel-top {
  background-image: url("https://static.a6air.com/resource/banner/20210330/pannel_top_checkin.jpg");
  background-size: cover;
  border-radius: 10px;
  height: 120px;
  /* width: 945px; */
}
</style>