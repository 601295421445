<template>
    <div class="search-block">
        <div class="popcontext">
          <ul class="tab">
            <li >
              <b style="font-size:16px;">自助服务</b>
            </li>
              <li rel="hgroup-aoc"  :class="activeIndex=='li_checkin'?'active':''" id="li_checkin" @click="selectPannel('li_checkin')">
                  <span>自助值机</span>
              </li>
              <li
              rel="hgroup-booking"
              @click="selectPannel('li_dynamic')"
              :class="activeIndex=='li_dynamic'?'active':''"
              id="li_dynamic"
            >
              <span>航班动态</span>
            </li>
            <!--<li rel="hgroup-cuss" id="ga_cn_ybdj"><span>预办登机</span></li>-->
            <li rel="hgroup-order"  :class="activeIndex=='li_ticket'?'active':''" id="li_ticket" @click="selectPannel('li_ticket')">
              <span>客票验真</span>
            </li>
          </ul>
        </div>
      </div>
  </template>
  <script>
  export default {
    props:['activeIndex'],
    data() {
    return {
      input: ""
    };
   },
  methods: {
    selectPannel(pannelId) {
     // console.log(pannelId);
      document.getElementById("li_checkin").setAttribute("class", "");
      document.getElementById("li_ticket").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
      if(pannelId=="li_dynamic"){
        this.$router.push({path:'/service/aoc',query: {t:1}})
      }
      else if(pannelId=='li_checkin'){
        this.$router.push({path:'/service/checkin',query: {t:1}})
      }
      else if(pannelId=='li_ticket'){
        this.$router.push({path:'/service/checkticket',query: {t:1}})
      }
    }
  }
  }
  </script>
  <style scoped>
    
.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}

.popcontext{
      width:240px;
      box-shadow: rgb(234, 234, 234) 0px 1px 5px 1px;
      /* border: 1px solid rgb(238, 238, 238); */
      background-color: #ffffff;
    }
    .popcontext .tab {
      z-index: 1000;
      width: 240px;  
    }
    .popcontext .tab li {
      height: 60px;
      line-height: 60px;
      /* border-top: 1px solid #4e617f; */
     /* background-color: #F8F8F8FF;*/
      color: #000000;
      font-size: 14px;
      text-align: center;
      transition: all 0.3s ease 0s;
      -webkit-transition: all 0.5s ease 0s;
      cursor: pointer;
      /* margin-left: 10px; */
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      filter: alpha(Opacity=60);
      -moz-opacity: 0.6;
      opacity: 0.6;
    }
    
    /* border-bottom: 1px solid #d6003a; */
    .popcontext .tab li {
      line-height: 45px;
    }
    
    
    
    .popcontext .tab li:nth-child(2).active {
      border-top: 1px solid #f9f9f9;
    }
    .popcontext .tab li.active {
      background-color: #d70039 !important;
      font-size: 16px;
      color:#FFFFFF;
      margin-left: 0;
      padding-left: 10px;
      border-right: 1px solid #fff;
      /* -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px; */
      /* border-radius: 3px 0 0 3px; */
      filter: alpha(Opacity=100);
      -moz-opacity: 1;
      opacity: 1;
    }
    .popcontext hgroup {
      display: none;
      position: absolute;
      width: 460px;
      top: -410px;
      left: 0px;
      z-index: 1000;
      margin-left: 140px;
      background-color: #fff;
      /* -webkit-border-radius: 0 5px 5px 0;
      -moz-border-radius: 0 5px 5px 0; */
      /* border-radius: 0 5px 5px 0; */
    }
    .popcontext hgroup article {
      position: relative;
      height: 364px;
      padding-left: 30px;
    }
  </style>