<template>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="700px">
        <div v-html="regProtocalContent" style="height: 400px;line-height: 25px; overflow-y: scroll;overflow-x:hidden">
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="dialogVisible = false">已阅读</el-button>
        </span>
    </el-dialog>
</template>
<script>
    import { getOneByAliasCode } from '@/api/newsapi'
    export default {
        data() {
            return {
                                                                                                                                                                                               dialogVisible: false,
                regProtocalContent: '',
                dialogTitle:''
            };
        },
        mounted() {

        },
        methods: {
            showDialog(aliasCode,title) {
                this.dialogTitle=title;
                this.dialogVisible = true;
                this.regProtocalContent="";
                getOneByAliasCode({ "aliasCode": aliasCode }).then(res => {
                    if (res.success) {
                        this.regProtocalContent = res.data.newsContent;
                    }
                })
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => { });
            }
        }
    }
</script>