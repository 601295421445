<template>
    <el-dialog :visible="visible" width="400px" @close="visible=!visible">
        <div class="wxapp_dialog">
            <div class="title">请前往<span class="b">湖南航空小程序</span>使用值机选座服务，快人一步 即享舒适！</div>
            <div class="qrcode">
                <img src="../assets/wxapp_code.jpg" style="height:190px;"/>
            </div>
            <div class="tips">微信扫一扫打开小程序</div>
        </div>
    </el-dialog>
</template>
<script>
    import { getOneByAliasCode } from '@/api/newsapi'
    export default {
        data() {
            return {
                visible:true
            };
        },
        mounted() {

        },
        methods: {
            showDialog(aliasCode,title) {
                this.dialogTitle=title;
                this.dialogVisible = true;
                this.regProtocalContent="";
                getOneByAliasCode({ "aliasCode": aliasCode }).then(res => {
                    if (res.success) {
                        this.regProtocalContent = res.data.newsContent;
                    }
                })
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => { });
            }
        }
    }
</script>
<style>
.wxapp_dialog{
    height:300px;
    display:flex;
    flex-direction:column;
    align-items:center;
    color:#333;
    font-size:16px;
}
.wxapp_dialog .title{
    width:305px;
    text-align:center;
    line-height: 22px;
    font-weight: 500;
    padding-bottom:30px;
}
.wxapp_dialog .title .b{
    color:#D70038;
}
.wxapp_dialog .tips{
    color:#999999;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding-top:10px;
}
.wxapp_dialog .tips::before{
    content:'';
    background:url('https://static.a6air.com/sale/seat/icon_scan_red.png') no-repeat 0 center;
    width:20px;
    height:20px;
    background-size:16px;
    display:inline-block;
    padding-right:4px;
}
</style>